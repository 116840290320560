import { graphql, Link, useStaticQuery } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";
import { Menu } from "./Menu";

type PageProps = {
  title?: string;
  description?: string;
};

export const Page: React.FC<PageProps> = ({ title, description, children }) => {
  const metadata = useStaticQuery<GatsbyTypes.SiteMetadataQuery>(graphql`
    query SiteMetadata {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `);

  const siteTitle = [title, metadata.site?.siteMetadata?.title]
    .filter((t) => t)
    .join(" / ");

  return (
    <>
      <Helmet>
        <html lang="de" />
        <title>{siteTitle}</title>
        <meta
          name="description"
          content={description || metadata.site?.siteMetadata?.description}
        />
      </Helmet>
      <div className="container">
        <div className="bg-gray-800">
          <div className="flex justify-center items-center py-8 sm:py-10">
            <Link to="/">
              <img
                src="/assets/logo.png"
                alt="United Brass"
                className="h-24 sm:h-auto"
              />
            </Link>
          </div>
          <Menu />
        </div>
        <div className="bg-white p-6 md:p-12">{children}</div>
        <div className="flex justify-end bg-gray-900 text-white text-sm px-12 py-2 space-x-4">
          <Link
            to="/impressum"
            className="hover:text-red-500"
            activeClassName="underline"
          >
            Impressum
          </Link>
        </div>
      </div>
    </>
  );
};
