import { Link } from "gatsby";
import React from "react";

type ItemProps = {
  path: string;
  title: string;
};

export const Item: React.FC<ItemProps> = ({ title, path }) => {
  return (
    <li>
      <Link
        to={path}
        className="relative flex justify-center px-2 py-3 sm:p-4 transition hover:text-red-500"
        activeClassName="after:absolute after:bottom-0 after:h-1 after:w-full after:bg-red-500"
      >
        {title}
      </Link>
    </li>
  );
};

export const Menu: React.FC = () => {
  return (
    <ul className="flex justify-center sm:px-8 sm:text-lg text-white bg-gray-900 overflow-scroll">
      <Item path="/" title="Start" />
      <Item path="/band" title="Band" />
      <Item path="/geschichte" title="Geschichte" />
      <Item path="/presse" title="Presse" />
      <Item path="/fotos" title="Fotos" />
    </ul>
  );
};
